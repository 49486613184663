@import "@/styles/globals.scss";
@font-face {
  font-family: 'DIN EngschriftStd';
  src: url('fonts/DIN-EngschriftStd.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('fonts/DINOT-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('fonts/DINOT-MediumItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('fonts/DINOT-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'DINOT';
  src: url('fonts/DINOT-BoldItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: 600;
}
